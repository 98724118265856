<template>
  <div>
    <HeaderContent :list="items" :label="$t('title.ad')">
      <custom-input
        placeholder="Search Ad"
        class="mr-4"
        width="200"
        v-model="payloadSearch"
        @keyup.enter="handleSearch"
      />
      <custom-button class="white--text" color="primary" @click="handleClick">{{
        $t("button.adAdd")
      }}</custom-button>
    </HeaderContent>

    <v-data-table
      :headers="headers"
      hide-default-footer
      disable-filtering
      disable-sort
      :items="ads"
      class="grey--text"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <span class="link" @click="moveEdit(item)">View Ad Detail</span>
          </td>
          <td>{{ item.adTitle }}</td>
          <td>{{ item.createAt }}</td>
          <td>{{ item.duration }} Days</td>
          <td>{{ remainingTimeShow(item.remainingTime) }}</td>
          <td>{{ item.adStatus === 3 ? "Ongoing" : (item.adStatus === 1 ? "Stop" : "Expired")}}</td>
          <td>
            <div class="d-flex justify-space-between align-center">
              <custom-button v-if="item.adStatus === 1" class="white--text" color="primary" @click="openModalStart(item.id)">Start Ad</custom-button>
              <custom-button v-if="item.adStatus === 3"  class="grey-button" @click="openModalStop(item.id)">Stop Ad</custom-button>
            </div>
            <!-- Error Popup -->
            <div v-if="isErrorPopupOpen" class="error-popup-overlay" @click="closeErrorPopup">
              <div class="error-popup-content" @click.stop>
                <button class="error-close-btn" @click="closeErrorPopup">X</button>
                <p>{{ errorMessage }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-space-between align-center">
              <v-btn @click="openModalDelete(item.id)" icon>
                <v-icon x-small>$delete</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>

      </template>
    </v-data-table>

    <Dialog-Delete
      title="Yakin menghapus Advertising ini?"
      :dialog="dialog"
      @closeDialog="closeDialog"
      @handleDelete="handleDelete"
    ></Dialog-Delete>
    <Dialog-Confirm
            title="Apakah Anda yakin mengaktifkan iklan ini?"
            :dialog="startAd"
            @closeDialog="closeDialog"
            @handleConfirm="updateStatus(idUser, 1)"
    ></Dialog-Confirm>
    <Dialog-Confirm
            title="Apakah Anda yakin untuk menghentikan iklan ini?"
            :dialog="stopAd"
            @closeDialog="closeDialog"
            @handleConfirm="updateStatus(idUser, 0)"
    ></Dialog-Confirm>

    <v-pagination
      class="d-flex justify-end"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      v-model="page"
      :length="totalPages"
      @input="getChannelByPage"
    ></v-pagination>
  </div>
</template>

<script>
  import HeaderContent from "../../../containers/HeaderContent";
  import DialogDelete from "../../../components/material/Dialog/DialogDelete";
  import DialogConfirm from "../../../components/material/Dialog/DialogConfirm";
  import {mapActions} from "vuex";

  export default {
  components: {
    HeaderContent,
    DialogDelete,
    DialogConfirm,
  },
  data() {
    return {
      idUser: "",
      showEditDialog: false,
      dialog: false,
      startAd: false,
      stopAd: false,
      loading: false,
      page: 1,
      totalPages: 0,
      isErrorPopupOpen: false,
      errorMessage: '',
      ad: {
        medias: [],
        adTitle: '',
        adStatus: '',
        duration: 0,
        remainingTime: 0,
        createAt: '',
        id: 0,
        accountIds: [],
        adCampaignType: '',
        accountType: ''
      },
      items: [
        {
          text: "Manage Advertising",
          disabled: false,
          href: "/post/ad",
        },
      ],
      payloadSearch: "",
      headers: [
        {
          text: "Details",
          value: "no",
          width: "90",
          class: "whitesnow",
        },
        {
          text: "Ad Title",
          value: "channelImage",
          width: "180",
          class: "whitesnow",
        },
        {
          text: "Create Date",
          value: "channelName",
          width: "100",
          class: "whitesnow",
        },
        {
          text: "Ad Duration",
          value: "channelType",
          width: "50",
          class: "whitesnow",
        },
        {
          text: "Remaining",
          value: "description",
          width: "50",
          class: "whitesnow",
        },
        {
          text: "Status",
          value: "action",
          class: "whitesnow",
          width: "50",
        },
        {
          text: "Action",
          value: "isDelete",
          class: "whitesnow",
          width: "50",
        },
        {
          text: "",
          value: "",
          class: "whitesnow",
          width: "180",
        },
      ],
      ads: [],
    };
  },
  created() {
    this.getResponseAd();
  },
  methods: {
    ...mapActions({
      listAd: "ad/getListAd",
      updateAdStatus: "ad/updateAdStatus",
      deleteAd: "ad/deleteAd",
      searchAd: "ad/searchAd",
    }),
    async getResponseAd() {
      const payload = {
        page: 0,
      };
      const response = await this.listAd(payload);
      if (response.status === 200) {
        const responseData = response.data.data;
        this.formatingResponse(responseData);
      } else {
        return response;
      }
    },
    async updateStatus(adId, newStatus) {
      const payload = {
        id: adId,
        status: newStatus
      };
      const response = await this.updateAdStatus(payload);
      this.loading = true;
      if (response.status === 200) {
        await this.getResponseAd();
        this.dialog = false;
        this.startAd = false;
        this.stopAd = false;
        this.idUser = "";
        this.loading = false;
      } else {
        const responseString = JSON.stringify(response, null, 2);
        if(responseString.includes('status code 400')){
          this.errorMessage = 'Lapak iklan untuk kontenmu telah terisi oleh aplikasi';
          this.isErrorPopupOpen = true;
        }
        this.dialog = false;
        this.startAd = false;
        this.stopAd = false;
        this.idUser = "";
        this.loading = false;
        return response;
      }
    },
    remainingTimeShow(remainingTime) {
      if (remainingTime >= 24) {
        return `${Math.floor(remainingTime / 24)} Days`;
      } else {
        return `${remainingTime % 24} Hours`;
      }
    },
    closeErrorPopup() {
      this.isErrorPopupOpen = false;
    },
    async getChannelByPage() {
      const payload = {
        page: this.page - 1,
      };
      const response = await this.listAd(payload);
      if (response.status === 200) {
        const responseData = response.data.data;
        this.formatingResponse(responseData);
      } else {
        return response;
      }
    },
    async handleSearch() {
      const payload = {
        page: 0,
        search: this.payloadSearch,
      };
      const response = await this.searchAd(payload);
      if (response.status === 200) {
        const responseData = response.data.data;
        this.formatingResponse(responseData);
      } else {
        return response;
      }
    },
    formatingResponse(response) {
      this.totalPages = response.totalPages;
      const content = response.content;
      this.ads = content.map((res, index) => {
        return {
          adTitle: res.adTitle,
          adStatus: res.adStatus,
          duration: res.duration,
          remainingTime: res.remainingTime,
          medias: res.medias,
          createAt: res.createAt,
          accountIds: res.accountIds,
          adCampaignType: res.adCampaignType,
          accountType: res.accountType,
          id: res.adId,
          no: index + 1,
        };
      });
    },
    handleClick() {
      this.$router.push("/ad/create");
    },
    moveEdit(payload) {
      if(payload.accountIds.length === 0 || payload.accountIds[0] === 0){
        payload.accountIds = [];
      }
      this.$router.push({
        name: "adEdit",
        params: {
          id: payload.id,
          adTitle: payload.adTitle,
          adStatus: payload.adStatus,
          duration: payload.duration,
          remainingTime: payload.remainingTime,
          medias: JSON.stringify(payload.medias),
          createAt: payload.createAt,
          accountIds: JSON.stringify(payload.accountIds),
          adCampaignType: payload.adCampaignType,
          accountType: (payload.accountIds.length === 0 || payload.accountIds[0] === 0) ? 'ALL' : 'SPECIFIC',
        },
      });
    },
    openModalDelete(id) {
      this.dialog = true;
      this.idUser = id;
    },
    openModalStart(id) {
      this.startAd = true;
      this.idUser = id;
    },
    openModalStop(id) {
      this.stopAd = true;
      this.idUser = id;
    },
    closeDialog() {
      this.dialog = false;
      this.startAd = false;
      this.stopAd = false;
      this.idUser = "";
    },
    async handleDelete() {
      const id = this.idUser;
      const response = await this.deleteAd(id);
      this.loading = true;
      if (response.status === 200) {
        await this.getResponseAd();
        this.dialog = false;
        this.idUser = "";
        this.loading = false;
      } else {
        return response;
        this.dialog = false;
        this.idUser = "";
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.image
  &__box
    width: 50px
    height: 50px
    background-color: grey
    border-radius: 5px
    overflow: hidden
  &__failed
    background: grey
  &__container
    padding: 10px 0
.manage
  &__box
    width: 80px
.grey-button
  background-color: #DEDEDE
  color: black
  border: none
.link
  color: #007bff
  text-decoration: underline
  cursor: pointer

.error-popup-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.2)
  display: flex
  justify-content: center
  align-items: center
  z-index: 1000

.error-popup-content
  position: relative
  padding-top: 30px
  padding-bottom: 10px
  background: white
  border-radius: 8px
  max-width: 300px
  width: 90%
  display: flex
  flex-direction: column
  align-items: center
  text-align: center

/* Close button styling */
.error-close-btn
  position: absolute
  top: 10px
  right: 10px
  border: none
  background: red
  font-size: 16px
  cursor: pointer
  color: white
  border-radius: 50%
  width: 20px
  height: 20px
  display: flex
  align-items: center
  justify-content: center

</style>
